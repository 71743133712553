<template>
  <div
    v-if="moduleStatus === 'success'"
    class="d-flex flex-column flex-grow-1 overflow-y-hidden"
  >
    <div v-if="selectedLocation" class="d-flex flex-column flex-1">
      <dhme-station-dashboard-toolbar
        :websockets-connected="websocketsConnected"
      />
      <div class="d-flex flex-1">
        <div
          v-if="showPlanningSidebar"
          class="px-3 py-2 d-flex flex-column"
          :style="{ height: screenHeight }"
        >
          <div
            ref="assemblyTasksScrollContainer"
            class="flex-1 pr-2 mb-2 overflow-y-auto flex-scroll-height"
          >
            <dhme-station-dashboard-assembly-task-card
              v-for="task in assemblyTasks"
              :id="`assembly-task-${task.id}`"
              :key="task.id"
              :task="task"
              :focused-task="focusedTask"
            />
          </div>

          <v-card outlined @click="linkScanner = true">
            <v-card-text class="d-flex align-center justify-center">
              <qr-code :value="selectedLocation.id" :options="{ width: 150 }" />
            </v-card-text>
          </v-card>
        </div>

        <dhme-station-dashboard-viewer
          v-if="focusedTask"
          ref="station-dashboard-viewer"
        />

        <div
          v-else
          class="flex-1 d-flex align-center justify-center font-italic"
        >
          No module on this location
        </div>

        <div
          v-if="assemblySubTasks.length > 0 && showTasksSidebar"
          class="px-3 py-2 d-flex flex-column"
          style="max-width: 450px"
          :style="{ height: screenHeight }"
        >
          <v-progress-linear
            height="15"
            :value="totalProgressAcrossSteps"
            color="primary"
            class="mb-2"
            rounded
          >
            <strong
              class="fs-10"
              :style="{
                color: getBlackOrWhiteBasedOnBackground(
                  $vuetify.theme.themes.light.primary
                ),
              }"
              >{{ Math.ceil(totalProgressAcrossSteps) }}%</strong
            >
          </v-progress-linear>
          <v-card class="mb-2 overflow-hidden" outlined>
            <v-tabs
              v-model="stepTab"
              center-active
              show-arrows
              color="primary"
              grow
            >
              <v-tab
                v-for="step in assemblyLocationSteps"
                :key="`assembly-location-step-${step?.id ? step.id : step}`"
                @click="whisperStep"
                >{{ step?.id ? `${step?.order} - ${step?.title}` : step }}
                <v-icon v-if="getStepProgress(step) === 100" dense
                  >mdi-check</v-icon
                >
              </v-tab>
            </v-tabs>
          </v-card>

          <div class="flex-1 overflow-y-auto flex-scroll-height">
            <v-menu
              v-if="!isStepSignedOff"
              v-model="signOfMenu"
              bottom
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="primary"
                  class="full-width mb-2"
                  :disabled="hasOpenTasks"
                  v-on="on"
                  ><v-icon class="mr-2">mdi-checkbox-marked</v-icon>Complete
                  Step</v-btn
                >
              </template>
              <v-card>
                <v-card-title>Complete Step</v-card-title>
                <v-card-text>
                  <ant-input label="User to sign off">
                    <template #input-field>
                      <v-text-field
                        v-model="signOfUser"
                        autofocus
                        filled
                        dense
                        placeholder="Name"
                        single-line
                        hide-details
                      />
                    </template>
                  </ant-input>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="error"
                    @click="
                      signOfUser = '';
                      signOfMenu = false;
                    "
                    >close</v-btn
                  >
                  <v-btn
                    color="success"
                    :disabled="signOfUser.length < 2"
                    elevation="0"
                    @click="stepSignOff"
                    >Complete</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
            <dhme-station-dashboard-assembly-sub-task-card
              v-for="subTask in locationSubTasks
                .filter(
                  (t) =>
                    t.task_type?.custom_6?.toString() ===
                    (assemblyLocationSteps[stepTab]?.id ??
                      assemblyLocationSteps[stepTab])
                )
                .sort(
                  (a, b) =>
                    parseFloat(a.task_type.custom_5) -
                    parseFloat(b.task_type.custom_5)
                )"
              :key="subTask.id"
              :task="subTask"
              :focused-sbs-code="
                $refs['station-dashboard-viewer']?.highlightedSbsCode ?? ''
              "
              :viewer-style="
                $refs['station-dashboard-viewer']?.viewerStyle ?? '2d'
              "
              @cardClicked="
                $refs['station-dashboard-viewer'].highlightInModel(subTask)
              "
              @scanElement="
                elementScanner = true;
                elementTask = subTask;
              "
              @takePhoto="
                takePictureDialog = true;
                uploadImageTask = subTask;
              "
              @taskClosed="
                subTask.status = 'closed';
                $refs['station-dashboard-viewer'].colorCurrentElements();
              "
              @newIssue="
                issueTask = subTask;
                newIssueDialog = true;
              "
              @previewImage="previewImage"
            />
            <dhme-station-dashboard-assembly-sub-task-card
              v-for="subTask in previousLocationsSubTasks.sort(
                (a, b) =>
                  parseFloat(a.task_type.custom_5) -
                  parseFloat(b.task_type.custom_5)
              )"
              :key="subTask.id"
              :from-previous="true"
              :task="subTask"
              :focused-sbs-code="
                $refs['station-dashboard-viewer']?.highlightedSbsCode ?? ''
              "
              :viewer-style="
                $refs['station-dashboard-viewer']?.viewerStyle ?? '2d'
              "
              @cardClicked="
                $refs['station-dashboard-viewer'].highlightInModel(subTask)
              "
              @scanElement="
                elementScanner = true;
                elementTask = subTask;
              "
              @takePhoto="
                takePictureDialog = true;
                uploadImageTask = subTask;
              "
              @taskClosed="
                subTask.status = 'closed';
                $refs['station-dashboard-viewer'].colorCurrentElements();
              "
              @newIssue="
                issueTask = subTask;
                newIssueDialog = true;
              "
            />
          </div>
          <v-btn
            v-if="locationIsLast"
            color="primary"
            class="full-width mt-2"
            :disabled="stepTab !== assemblyLocationSteps.length - 1"
            @click="completeAssemblyDialog = true"
            ><v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>Complete
            Assembly</v-btn
          >
        </div>
      </div>
    </div>
    <dhme-station-dashboard-select-location-dialog
      :displayed="locationDialogDisplayed"
    />
    <dhme-station-dashboard-complete-assembly-dialog
      :displayed="completeAssemblyDialog"
      @close="completeAssemblyDialog = false"
      @completed="init"
    />
    <dhme-station-dashboard-new-issue-dialog
      :task="issueTask"
      :displayed="newIssueDialog"
      @close="
        issueTask = {};
        newIssueDialog = false;
      "
    />
    <take-picture-dialog
      :display-dialog="takePictureDialog"
      @closeDialog="
        takePictureDialog = false;
        uploadImageTask = null;
      "
      @uploadImage="uploadImage"
    />
    <v-dialog key="table-preview" v-model="previewDialog">
      <div style="height: 80vh" class="iframe-container">
        <v-img contain :src="imagePreview?.url" height="100%" width="100%" />
        <div class="iframe-actions-container">
          <v-btn color="error" small plain @click="previewDialog = false">
            <v-icon> mdi-close </v-icon>Close
          </v-btn>
          <v-spacer class="mx-5" />

          <v-menu
            v-model="deleteImageMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                small
                elevation="0"
                :disabled="canDeletePreview"
                v-on="on"
              >
                <v-icon dense class="mr-2">mdi-delete</v-icon>Delete
              </v-btn>
            </template>

            <v-card>
              <v-card-text
                >Are you sure you want to delete this image?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn small text @click="deleteImageMenu = false"> No </v-btn>
                <v-btn
                  small
                  color="success"
                  elevation="0"
                  @click="deleteTaskPreviewImage"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      key="element-scanner"
      v-model="elementScanner"
      max-width="90%"
      @click:outside="elementScanner = false"
      @keydown.esc="elementScanner = false"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline"
          >Scan element
        </v-card-title>
        <v-divider />
        <v-card-text>
          <base-ant-scanner
            :decode-parser="elementIdDecodeParser"
            @decodedResult="elementScan"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      key="location-scanner"
      v-model="linkScanner"
      max-width="90%"
      @click:outside="linkScanner = false"
      @keydown.esc="linkScanner = false"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline"
          >Scan location
        </v-card-title>
        <v-divider />
        <v-card-text>
          <base-ant-scanner @decodedResult="linkDevices" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="d-flex justify-center align-center">
    <ant-loading />
  </div>
</template>

<script>
import { DHME_M_STATION_DASHBOARD } from '@/modules/modules';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import BaseAntScanner from '@/components/Base/BaseAntScanner.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import moment from 'moment';
import TakePictureDialog from '@/components/Dialogs/TakePictureDialog.vue';
import { LaravelEchoClient } from '@/services/laravel-echo';
import DhmeStationDashboardSelectLocationDialog from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardSelectLocationDialog.vue';
import DhmeStationDashboardToolbar from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardToolbar.vue';
import DhmeStationDashboardViewer from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardViewer.vue';
import DhmeStationDashboardAssemblyTaskCard from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardAssemblyTaskCard.vue';
import DhmeStationDashboardAssemblySubTaskCard from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardAssemblySubTaskCard.vue';
import { getBlackOrWhiteBasedOnBackground } from '@/services/color-helper';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import DhmeStationDashboardCompleteAssemblyDialog from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardCompleteAssemblyDialog.vue';
import DhmeStationDashboardNewIssueDialog from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardNewIssueDialog.vue';
import AntInput from '@/components/AntInput.vue';
import {
  createMessageV2,
  downloadAppendixV2,
  removeAppendixV2,
} from '@/services/api/v2/tasks.v2.api';
import FileHandlerService from '@/services/file-handler';
import viewportStyleMixin from '@/Mixins/ViewportStyleMixin';

export default {
  name: 'DhmeStationDashboard',
  components: {
    AntInput,
    DhmeStationDashboardNewIssueDialog,
    DhmeStationDashboardCompleteAssemblyDialog,
    DhmeStationDashboardAssemblySubTaskCard,
    DhmeStationDashboardAssemblyTaskCard,
    DhmeStationDashboardViewer,
    DhmeStationDashboardSelectLocationDialog,
    DhmeStationDashboardToolbar,
    TakePictureDialog,
    BaseAntScanner,
    AntLoading,
  },
  mixins: [viewportStyleMixin],
  data: () => {
    return {
      moduleScanner: false,
      elementScanner: false,
      linkScanner: false,
      takePictureDialog: false,
      uploadImageTask: null,
      elementTask: null,
      locationDialogDisplayed: false,
      stepTab: 0,
      completeAssemblyDialog: false,
      locationIsLast: false,
      issueTask: {},
      newIssueDialog: false,
      signOfUser: '',
      signOfMenu: false,
      websocketsConnected: false,
      hallChannel: null,
      previewDialog: false,
      imagePreview: null,
      deleteImageMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      authenticatedUser: 'authenticatedUser',
      moduleStatus: 'dhmeStationDashboard/status',
      halls: 'dhmeStationDashboard/halls',
      locations: 'dhmeStationDashboard/assemblyLocations',
      locationTypes: 'dhmeStationDashboard/locationTypes',
      selectedLocation: 'dhmeStationDashboard/selectedLocation',
      selectedHall: 'dhmeStationDashboard/selectedHall',
      assemblyTasks: 'dhmeStationDashboard/assemblyTasks',
      focusedTask: 'dhmeStationDashboard/focusedAssemblyTask',
      assemblySubTasks: 'dhmeStationDashboard/assemblySubTasks',
      moduleId: 'dhmeStationDashboard/moduleId',
      showTasksSidebar: 'dhmeStationDashboard/showTaskSidebar',
      showPlanningSidebar: 'dhmeStationDashboard/showPlanningSidebar',
      configurationSteps: 'dhmeStationDashboard/selectedConfigurationSteps',
    }),
    screenHeight() {
      return `${this.viewportPixelHeight.height - 70}px`;
    },
    canDeletePreview() {
      return (
        this.assemblySubTasks.find((t) => t.id === this.imagePreview?.task)
          ?.status !== 'open'
      );
    },
    locationSubTasks() {
      if (this.selectedHall.automated) {
        return this.assemblySubTasks.filter(
          (t) => t.task_type.custom_2 === this.selectedLocation.id
        );
      }
      return this.assemblySubTasks;
    },
    previousLocationsSubTasks() {
      if (this.selectedHall.automated) {
        return this.assemblySubTasks.filter(
          (t) => t.task_type.custom_2 !== this.selectedLocation.id
        );
      } else {
        return [];
      }
    },
    moduleId() {
      return this.project?.modules.find(
        (module) => module.route === DHME_M_STATION_DASHBOARD
      ).id;
    },
    assemblyLocationSteps() {
      let steps = Array.from(
        new Set(this.locationSubTasks.map((t) => t?.task_type?.custom_6))
      );

      // Check if all elements are UUIDs
      let isUuid = steps.every((step) => {
        const uuidRegex =
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
        return uuidRegex.test(step);
      });

      if (isUuid) {
        return this.configurationSteps
          .filter((s) => steps.includes(s.id))
          .sort((a, b) => parseInt(a.order) - parseInt(b.order));
      } else {
        return steps.sort((a, b) => parseInt(a) - parseInt(b));
      }
    },
    totalProgressAcrossSteps() {
      const closedTasks = this.locationSubTasks.filter((t) =>
        ['closed', 'canceled'].includes(t.status)
      ).length;

      if (closedTasks > 0) {
        return Math.ceil((closedTasks / this.locationSubTasks.length) * 100);
      }
      return 0;
    },
    isStepSignedOff() {
      return this.focusedTask.activities.some((t) =>
        t.value.endsWith(
          `: has signed off step '${this.assemblyLocationSteps[this.stepTab]?.title}' on location ${this.selectedLocation.id}`
        )
      );
    },
    hasOpenTasks() {
      let tasks = this.assemblySubTasks.filter(
        (t) =>
          t.task_type?.custom_6?.toString() ===
          this.assemblyLocationSteps[this.stepTab]
      );

      return tasks.some((t) => t.status === 'open');
    },
  },
  watch: {
    focusedTask(newValue, oldValue) {
      if (newValue?.id !== oldValue?.id) {
        this.$store.commit(
          'dhmeStationDashboard/reset_location_assembly_tasks'
        );
        if (this.focusedTask?.task_type?.type !== 'dhme-assembly-space') {
          this.$store.dispatch(
            'dhmeStationDashboard/fetchAssemblySubTasksByLocation'
          );
        }
        if (newValue?.id) {
          this.scrollToCurrentTask(newValue.id);
        }
      }
    },
    selectedLocation: {
      async handler(newValue, oldValue) {
        this.$store.commit(
          'dhmeStationDashboard/reset_location_assembly_tasks'
        );
        if (newValue) {
          this.locationDialogDisplayed = false;
          if (this.selectedHall?.automated) {
            await this.$store.dispatch(
              'dhmeStationDashboard/fetchStationDashboardCurrentTact'
            );
          }
          await this.$store.dispatch(
            'dhmeStationDashboard/fetchStationDashboardModuleAssemblyTasks'
          );
          this.stepTab = 0;

          await this.subscribeToHallEvents();

          if (oldValue) {
            const channelName = `App.Project.${this.project?.id}.Module.${this.moduleId}.Location.${oldValue?.id}`;
            const channel = LaravelEchoClient.private(channelName);
            channel.stopListening(channel);
          }
          await this.subscribeToLocationEvents();

          await this.isLastLocation();
        } else {
          this.locationDialogDisplayed = true;
        }
      },
    },
    project: {
      immediate: true,
      async handler(newValue) {
        if (newValue && newValue?.master_id === newValue?.id) {
          await this.init();
        }
      },
    },
    'hallChannel.subscription'(value) {
      console.log(value);
    },
  },
  async mounted() {
    await this.init();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  },
  methods: {
    async deleteTaskPreviewImage() {
      await removeAppendixV2(this.imagePreview.task, this.imagePreview.id);
      let task = this.locationSubTasks.find(
        (x) => x.id === this.imagePreview.task
      );
      task.appendixes.splice(
        task.appendixes.findIndex((x) => x.id === this.imagePreview.id),
        1
      );

      this.imagePreview = null;
      this.previewDialog = false;
    },
    async previewImage(attachment) {
      let file = await downloadAppendixV2(attachment.task, attachment.id);

      let blob = await FileHandlerService.convertBase64ToBlob(
        file.file,
        file.mimetype
      );

      attachment.url = URL.createObjectURL(blob);

      this.imagePreview = attachment;
      this.previewDialog = true;
    },
    async stepSignOff() {
      const message = await createMessageV2(this.focusedTask.id, {
        message: `${this.signOfUser}: has signed off step '${this.assemblyLocationSteps[this.stepTab]?.title}' on location ${this.selectedLocation.id}`,
      });

      this.focusedTask.activities.unshift(message);

      this.$store.commit('showNotification', {
        content: `Successfully signed off step '${this.stepTab}'`,
        color: 'success',
      });
      this.signOfUser = '';
      this.signOfMenu = false;
    },
    isLastLocation: async function () {
      if (!this.selectedHall.automated) {
        this.locationIsLast = true;
        return;
      }

      const { locations } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: this.project.id,
            as: 'locations',
            sortBy: 'order',
            columns: [
              {
                name: 'hall_configuration',
                conditions: [
                  {
                    operator: '=',
                    value: this.selectedLocation.hall_configuration,
                  },
                ],
              },
              {
                name: 'order',
                conditions: [
                  {
                    operator: '>',
                    value: this.selectedLocation.order,
                  },
                ],
              },
              {
                name: 'assembly_location',
              },
            ],
          },
        ],
      });

      this.locationIsLast = locations.records.length === 0;
    },
    getStepProgress(step) {
      let tasks = this.assemblySubTasks.filter(
        (t) => t.task_type?.custom_6?.toString() === step
      );
      const closedTasks = tasks.filter((t) => t.status === 'closed').length;

      if (closedTasks > 0) {
        return Math.ceil((closedTasks / tasks.length) * 100);
      }
      return 0;
    },
    getBlackOrWhiteBasedOnBackground,
    async init() {
      await this.$store.dispatch(
        'dhmeStationDashboard/fetchStationDashboardModuleData',
        {
          projectId: this.project.id,
          moduleId: this.moduleId,
          sessionId: this.$route.params.sessionId ?? null,
        }
      );
      await this.$store.dispatch('dhmeStationDashboard/fetchHalls');

      if (this.$route.query.location) {
        const location = this.locations.find(
          (item) => item.id === this.$route.query.location
        );
        const hall = this.halls.find(
          (item) => item.id === location.assembly_hall
        );
        await this.$store.dispatch(
          'dhmeStationDashboard/selectStationDashboardHall',
          hall
        );
        await this.$store.commit(
          'dhmeStationDashboard/set_assembly_location',
          location
        );
      } else {
        this.locationDialogDisplayed = true;
      }
    },
    scrollToCurrentTask() {
      this.$nextTick(() => {
        // Ensure that the DOM has been updated
        const scrollContainer = this.$refs.assemblyTasksScrollContainer;
        const selectedAssemblyTask = document.getElementById(
          `assembly-task-${this.focusedTask.id}`
        );

        if (scrollContainer && selectedAssemblyTask) {
          const scrollTo =
            selectedAssemblyTask.offsetTop -
            scrollContainer.clientHeight / 2 -
            selectedAssemblyTask.offsetHeight / 2;

          scrollContainer.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }
      });
    },
    whisperStep() {
      LaravelEchoClient.private(
        `App.Project.${this.project.id}.Module.${this.moduleId}.Location.${this.selectedLocation.id}`
      ).whisper('assemblyStepper', this.stepTab);
    },
    async handleVisibilityChange() {
      if (!document.hidden && this.selectedLocation) {
        // Device is unlocked or the application is brought to the foreground
        if (this.selectedHall?.automated) {
          await this.$store.dispatch(
            'dhmeStationDashboard/fetchStationDashboardCurrentTact'
          );
        }
        await this.$store.dispatch(
          'dhmeStationDashboard/fetchStationDashboardModuleAssemblyTasks'
        );

        if (this.focusedTask?.task_type?.type !== 'dhme-assembly-space') {
          await this.$store.dispatch(
            'dhmeStationDashboard/fetchAssemblySubTasksByLocation'
          );
        }

        await this.$refs['station-dashboard-viewer'].fetch2DDrawing();

        this.subscribeToHallEvents();
        this.subscribeToLocationEvents();
      }
    },
    async linkDevices(item) {
      await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'linkDevices',
        {
          linkId: item,
          location: this.selectedLocation,
        }
      );
      this.linkScanner = false;
    },
    elementIdDecodeParser(value) {
      return /^http/.test(value) ? value : JSON.parse(value);
    },
    subscribeToHallEvents() {
      if (this.project && this.moduleId && this.selectedLocation) {
        const channelName = `App.Project.${this.project.id}.Module.${this.moduleId}.Hall.${this.selectedLocation?.assembly_hall}`;
        const channel = LaravelEchoClient.private(channelName);

        // Check the connection state
        const connectionState =
          LaravelEchoClient.connector.pusher.connection.state;
        console.log('Current WebSocket connection state:', connectionState);

        this.websocketsConnected = connectionState === 'connected';
        const eventHandler = async (event, content) => {
          switch (event) {
            case 'DhmeNewTactIn':
              this.$store.commit('showNotification', {
                content: `New tact starts in ${content.data.minutes} minutes`,
                color: 'warning',
              });
              break;
            case 'DhmeNewTact':
              const randomSleep = Math.floor(Math.random() * 7001);
              await new Promise((resolve) => setTimeout(resolve, randomSleep));

              await this.$store.dispatch(
                'dhmeStationDashboard/fetchStationDashboardModuleAssemblyTasks'
              );
              await this.$store.dispatch(
                'dhmeStationDashboard/fetchStationDashboardCurrentTact'
              );
              await this.$store.dispatch(
                'dhmeStationDashboard/fetchAssemblySubTasksByLocation'
              );

              this.$store.commit('showNotification', {
                content: 'New tact started',
                color: 'success',
              });
              break;
            case 'DhmeCancelModule':
              await this.$store.dispatch(
                'fetchStationDashboardModuleAssemblyTasks'
              );
              break;
          }
        };

        // Subscribe to the channel
        channel.listenToAll(eventHandler);

        // Handle reconnection automatically
        LaravelEchoClient.connector.pusher.connection.bind('connected', () => {
          console.log(
            'WebSocket reconnected, re-subscribing to the channel...'
          );
          channel.listenToAll(eventHandler);
        });
      }
    },
    subscribeToLocationEvents() {
      if (this.project && this.moduleId && this.selectedLocation) {
        const channelName = `App.Project.${this.project?.id}.Module.${this.moduleId}.Location.${this.selectedLocation?.id}`;
        const channel = LaravelEchoClient.private(channelName);

        channel.listenForWhisper('assemblyStepper', (e) => {
          this.stepTab = e;
        });

        channel.listenForWhisper('viewerStyle', (e) => {
          this.$refs['station-dashboard-viewer'].viewerStyle = e;
        });

        channel.listenForWhisper('taskClosed', (e) => {
          this.assemblySubTasks.find((t) => t.id === e).status = 'closed';
        });

        channel.listenForWhisper('taskValidated', (e) => {
          this.assemblySubTasks.find((t) => t.id === e).task_type.custom_10 =
            '1';
        });

        channel.listenForWhisper('reloadData', async (e) => {
          this.$store.commit('showNotification', {
            content: 'Data is being reloaded',
            color: 'info',
          });
          await this.$store.dispatch(
            'dhmeStationDashboard/fetchStationDashboardModuleAssemblyTasks'
          );
          await this.$store.dispatch(
            'dhmeStationDashboard/fetchAssemblySubTasksByLocation'
          );
          await this.$store.dispatch(
            'dhmeStationDashboard/fetchStationDashboardCurrentTact'
          );
        });

        channel.listenForWhisper('hardReload', async (e) => {
          // Get the current URL
          const currentUrl = new URL(window.location.href);

          // Add a timestamp as a query parameter to force cache clearing
          const timestamp = new Date().getTime();
          currentUrl.searchParams.set('timestamp', timestamp.toString());

          // Modify the current URL without adding to the browser's history
          window.history.replaceState({}, document.title, currentUrl.href);

          // Reload the page
          window.location.reload(); // Pass true to force a hard reload
        });
      }
    },
    async uploadImage(base64) {
      const generateUniqueHash = () =>
        Math.random().toString(36).substring(2, 6);

      const appendix = {
        name: `image_${generateUniqueHash()}`,
        extension: 'jpeg',
        data: base64,
      };
      try {
        const response = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'uploadImageToTask',
          {
            task: this.uploadImageTask.id,
            appendix,
          }
        );
        this.$store.commit('showNotification', {
          content: 'Successfully uploaded image to task',
          color: 'success',
        });

        this.assemblySubTasks.find((x) => x.id === response.id).appendixes =
          response.appendixes;

        this.uploadImageTask = null;
        this.takePictureDialog = false;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    },
    elementScan(value) {
      // validate produuzit Id
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'validateElement',
        {
          task: this.elementTask.id,
          project: this.elementTask.project,
          element_id: this.elementTask.sbscode,
          production_id: /^http/.test(value) ? value : value.production_id,
          element_type: /^http/.test(value)
            ? this.elementTask.title.match(/\(([^)]+)\)/)[1]
            : value.element_type,
        }
      )
        .then((task) => {
          this.$store.commit('showNotification', {
            content: 'Element is validated',
            color: 'success',
          });

          this.elementTask.task_type.custom_10 = '1';

          LaravelEchoClient.private(
            `App.Project.${this.project.id}.Module.${this.moduleId}.Location.${this.selectedLocation.id}`
          ).whisper('taskValidated', this.elementTask.id);

          this.$nextTick(() => {
            this.elementScanner = false;
            this.elementTask = null;
          });
        })
        .catch((error) => {
          this.elementTask.task_type.custom_10 = false;
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.selected-location-task {
  border: solid 2px var(--v-primary-base);
}

.iframe-container {
  position: relative;

  .iframe-actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(8px);
    border-radius: 5px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 100px);
    z-index: 1;
    padding: 12px 16px;
  }
}
</style>
